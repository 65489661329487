const Trip = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="158" height="158" fill="white" stroke="#05293D" strokeWidth="2"/>
    <circle cx="33" cy="43" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
    <path d="M33 42.5L33 100.5C33 113.479 43.5213 124 56.5 124V124C69.4787 124 80 113.479 80 100.5L80 58.7207C80 45.742 90.5213 35.2207 103.5 35.2207V35.2207C116.479 35.2207 127 45.742 127 58.7207L127 110" stroke="#05293D" strokeWidth="2"/>
    <circle cx="127" cy="116" r="7" fill="#17E8DA" stroke="#05293D" strokeWidth="2"/>
    </svg>



  )
}

export default Trip;

