const Report = () => {

  return (

    <svg width="225" height="286" viewBox="0 0 225 286" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="223" height="284" fill="white" stroke="#05293D" strokeWidth="2"/>
      <rect x="19" y="24" width="92" height="82" stroke="#05293D" strokeWidth="2"/>
      <rect x="19" y="106" width="92" height="92" stroke="#05293D" strokeWidth="2"/>
      <rect x="110" y="105" width="90" height="14" transform="rotate(-180 110 105)"
            fill="url(#paint0_linear_545_4103)"/>
      <path
        d="M39.7535 75L52.9055 39.24H56.1215L69.2735 75H65.9135L62.6495 65.88H46.3775L43.0655 75H39.7535ZM47.3855 63H61.5935L53.9135 41.64H55.0655L47.3855 63Z"
        fill="#05293D"/>
      <path d="M79.756 70V62.044H71.836V59.884H79.756V52H81.916V59.884H89.836V62.044H81.916V70H79.756Z" fill="#05293D"/>
      <line x1="122" y1="47" x2="205" y2="47" stroke="#05293D" strokeWidth="2"/>
      <line x1="122" y1="62" x2="205" y2="62" stroke="#05293D" strokeWidth="2"/>
      <line x1="122" y1="77" x2="205" y2="77" stroke="#05293D" strokeWidth="2"/>
      <line x1="122" y1="92" x2="205" y2="92" stroke="#05293D" strokeWidth="2"/>
      <line x1="122" y1="107" x2="205" y2="107" stroke="#05293D" strokeWidth="2"/>
      <line x1="122" y1="122" x2="205" y2="122" stroke="#05293D" strokeWidth="2"/>
      <rect x="20" y="107" width="90" height="15" fill="#05293D"/>
      <rect x="2" y="254" width="152" height="30" fill="#05293D"/>
      <defs>
        <linearGradient id="paint0_linear_545_4103" x1="110" y1="119.001" x2="200" y2="119.001"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF6666"/>
          <stop offset="0.33" stopColor="#FA9751"/>
          <stop offset="0.67" stopColor="#FFF932"/>
          <stop offset="1" stopColor="#40FB76"/>
        </linearGradient>
      </defs>
    </svg>


  )
}

export default Report;

