const Chart = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="158" height="158" fill="white" stroke="#05293D" strokeWidth="2"/>
      <circle cx="47" cy="112" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <circle cx="97" cy="102" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <circle cx="121" cy="55" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <line x1="26" y1="25" x2="26" y2="135" stroke="#05293D" strokeWidth="2"/>
      <line x1="135" y1="136" x2="25" y2="136" stroke="#05293D" strokeWidth="2"/>
      <path d="M47 112L66 69L97.5 102L121.5 55" stroke="#05293D" strokeWidth="2"/>
      <circle cx="66" cy="69" r="7" fill="#17E8DA" stroke="#05293D" strokeWidth="2"/>
    </svg>


  )
}

export default Chart;

