const MachineLearning = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="158" height="158" fill="white" stroke="#05293D" strokeWidth="2"/>
      <circle cx="80" cy="36" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <circle cx="43" cy="58" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <circle cx="117" cy="58" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <circle cx="43" cy="102" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <circle cx="117" cy="102" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <circle cx="80" cy="124" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <line x1="42.4889" y1="57.1405" x2="79.4889" y2="35.1405" stroke="#05293D" strokeWidth="2"/>
      <line x1="79.4889" y1="123.14" x2="116.489" y2="101.14" stroke="#05293D" strokeWidth="2"/>
      <line x1="42.4889" y1="101.14" x2="79.4889" y2="79.1405" stroke="#05293D" strokeWidth="2"/>
      <line x1="79.4889" y1="79.1405" x2="116.489" y2="57.1405" stroke="#05293D" strokeWidth="2"/>
      <line x1="43.5111" y1="101.14" x2="80.5111" y2="123.14" stroke="#05293D" strokeWidth="2"/>
      <line x1="43.5111" y1="57.1405" x2="80.5111" y2="79.1405" stroke="#05293D" strokeWidth="2"/>
      <line x1="80.5111" y1="35.1405" x2="117.511" y2="57.1405" stroke="#05293D" strokeWidth="2"/>
      <line x1="80.5111" y1="79.1405" x2="117.511" y2="101.14" stroke="#05293D" strokeWidth="2"/>
      <circle cx="80" cy="80" r="7" fill="#17E8DA" stroke="#05293D" strokeWidth="2"/>
    </svg>


  )
}

export default MachineLearning;

