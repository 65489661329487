const Trip2 = () => {

  return (

    <svg width="160" height="161" viewBox="0 0 160 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1.5" width="158" height="158" fill="white" stroke="#05293D" strokeWidth="2"/>
      <path
        d="M69.5281 96.3994L48.9389 89.1982C37.4826 85.1913 25.5 93.6948 25.5 105.832C25.5 123.631 48.8839 130.207 58.1605 115.015L69.5281 96.3994ZM69.5281 96.3994L127 116.5M69.5281 96.3994L89.1603 64.2493M33 43.5004L89.1603 64.2493M89.1603 64.2493L109.953 71.9313C121.857 76.3292 134.5 67.5218 134.5 54.8316C134.5 36.4176 110.309 29.6155 100.713 45.3312L89.1603 64.2493Z"
        stroke="#05293D" strokeWidth="2" strokeLinecap="square"/>
      <circle cx="33" cy="43.5" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <circle cx="127" cy="116.5" r="7" fill="#17E8DA" stroke="#05293D" strokeWidth="2"/>
    </svg>


  )
}

export default Trip2;

