const Trip3 = () => {

  return (

    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="158" height="158" fill="white" stroke="#05293D" strokeWidth="2"/>
      <circle cx="33" cy="43" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2"/>
      <path d="M40 43L133 43V79.5L27 79.5L27 116L120 116" stroke="#05293D" strokeWidth="2"/>
      <circle cx="127" cy="116" r="7" fill="#17E8DA" stroke="#05293D" strokeWidth="2"/>
    </svg>


  )
}

export default Trip3;

